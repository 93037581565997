// Neue Frutiger Thai Modern W05Th
@font-face {
  font-family: 'NeueFrutigerTH';
  src: url('#{$netstorage-fonts-path}cjk/NeueFrutiger_TH/724135c9-ada2-4772-9030-718e6ae0a482.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'NeueFrutigerTH';
  src: url('#{$netstorage-fonts-path}cjk/NeueFrutiger_TH/724135c9-ada2-4772-9030-718e6ae0a482.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

// Neue Frutiger Thai Modern W05Md
@font-face {
  font-family: 'NeueFrutigerTH';
  src: url('#{$netstorage-fonts-path}cjk/NeueFrutiger_TH/4dae9253-8774-4376-9db0-c2662b9ba4cd.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'NeueFrutigerTH';
  src: url('#{$netstorage-fonts-path}cjk/NeueFrutiger_TH/4dae9253-8774-4376-9db0-c2662b9ba4cd.woff2')
    format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

html,
body {
  font-family: $th-primary-font;
}
